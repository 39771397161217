<!--
 * @Description: 
 * @Version: 
 * @Autor: houcf
 * @Date: 2023-04-21 17:49:43
 * @LastEditTime: 2023-04-24 15:43:01
-->
<template>
  <div>
    <ul>
      <li v-for="item in disList" :class="setBg(item)">{{item}}</li>
    </ul>
  </div>
</template>

<script >
export default {
  props: {
    disTypes: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      disList: []
    };
  },
  watch: {
    disTypes:{
      handler(val) {
      if (val) {
        this.disList = val.split(';').filter(e => e != '');
      }
    },
    immediate: true
    }
  },
  methods:{
    setBg(item){
      if(item.indexOf('不限') > -1 || item.indexOf('多重') > -1){
        return 'bg1'
      }
      if(item.indexOf('视力') > -1 || item.indexOf('智力') > -1){
        return 'bg2'
      }
      if(item.indexOf('听力') > -1 || item.indexOf('精神') > -1){
        return 'bg3'
      }
      if(item.indexOf('言语') > -1 ){
        return 'bg4'
      }
      if(item.indexOf('肢体') > -1 ){
        return 'bg5'
      }
    }
  }
};
</script>

<style lang="scss" scoped>
ul {
  display: flex;
    flex-wrap: wrap;
}
li {
  display: flex;
  align-items: center;
  min-width: 74px;
  height: 30px;
  background: #f1f2f1;
  border-radius: 4px;
  border: 1px solid #ebebeb;
  margin-right: 8px;
  margin-bottom: 8px;
  color: #4B534F;
  padding: 0 8px;
  &.bg1 {
    background: #f1f2f1;
    border: 1px solid #ebebeb;
  }
  &.bg2 {
    background: #ebf6f1;
    border: 1px solid #e1f0e9;
  }
  &.bg3 {
    background: #fff7ec;
    border: 1px solid #faf0e3;
  }
  &.bg4 {
    background: #fcedef;
    border: 1px solid #f7e4e6;
  }
  &.bg5 {
    background: #ecf5fa;
    border: 1px solid #e1eef5;
  }
  
}
</style>
