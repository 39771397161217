<!--
 * @Description: 
 * @Version: 
 * @Autor: houcf
 * @Date: 2023-04-23 10:47:41
 * @LastEditTime: 2023-04-26 17:28:21
-->
<template>
  <div class="project_detail">
    <div class="content">
      <div class="breadcrumb">
        <span class="name">当前位置：</span>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/train/' }">职业培训</el-breadcrumb-item>
          <el-breadcrumb-item>{{ detail.courseName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="my_content">
        <div class="init">
          <div class="header p18">
            <h2>
              {{ detail.courseName }}
            </h2>
            <p class="mt6"><span><label>培训主办方：</label>
                <span style="color:#66716c" class="bule">{{ detail.zhuName }}</span>
              </span></p>
            <p class="hover mt24"><span><label>培训承办方：</label>
                <span style="color:#66716c" class="bule">{{ detail.schooldName }}</span>
              </span></p>
          </div>
          <section class="p18">
            <h3 class="title">
              报名条件
            </h3>
            <ul class="ul">
              <li class="dict">
                <h4 class="h4">适合残疾<br>类别与等级</h4>
                <p class="p">
                  <disType :disTypes="detail.disTypeLevels" />
                </p>
              </li>
              <li>
                <h4 class="h4">年龄</h4>
                <p class="p">限{{
                    detail.isWei == 0 ? '男性16-60岁，女性16-55岁' : '最小' + (detail.ageMin || 16) + '岁~最大' + (detail.ageMax || 60)
                  }}</p>
              </li>
              <li>
                <h4 class="h4">性别</h4>
                <p class="p">{{ detail.userSex }}</p>
              </li>
              <li>
                <h4 class="h4">学历</h4>
                <p class="p">
                  {{ detail.userEdu }}
                </p>
              </li>
              <li>
                <h4 class="h4">户籍要求</h4>
                <p class="p" v-if="detail.isArea != '0'">
                  <span v-for="(item,index) in detail.courseAreas" style="padding-right: 5px">
                    {{
                      (item.provinceid || '') + (item.cityid ? '-' + item.cityid : '') + (item.threeCityid ? '-' + item.threeCityid : '')
                    }}
                  </span>
                </p>
                <p class="p" v-else>
                  <span style="color: #0077BB">不限户籍</span>
                </p>
              </li>
              <li>
                <h4>
                  其它条件
                </h4>
                <p>
                  需携带身份证及残疾证
                </p>
              </li>
            </ul>
          </section>
          <section class="p18">
            <h3 class="title">
              培训详情
            </h3>
            <ul class="ul">
              <li>
                <h4 class="h4">报名时间</h4>
                <p class="p">{{
                    $dateFormat(detail.appplyBeginTime, 'YYYY年MM月DD日')
                  }}-{{ $dateFormat(detail.appplyEndTime, 'YYYY年MM月DD日') }}</p>
              </li>
              <li>
                <h4 class="h4">培训时间</h4>
                <p class="p">{{
                    $dateFormat(detail.trainBeginTime, 'YYYY年MM月DD日')
                  }}-{{ $dateFormat(detail.trainEndTime, 'YYYY年MM月DD日') }}
                  {{
                    detail.timeMin ? detail.timeMin.substr(0, 5) : ''
                  }}-{{ detail.timeMax ? detail.timeMax.substr(0, 5) : '' }}</p>
              </li>
              <li>
                <h4 class="h4">培训类型</h4>
                <p class="p">{{ detail.cultivateType }}</p>
              </li>
              <li>
                <h4 class="h4">课程类别</h4>
                <p class="p">{{ detail.trainType }}-{{ detail.trainType2 }}</p>
              </li>
              <li>
                <h4 class="h4">课程等级</h4>
                <p class="p">{{ detail.grade }}</p>
              </li>
              <li>
                <h4 class="h4">培训模式</h4>
                <p class="p">{{ detail.trainMode }}</p>
              </li>
              <li>
                <h4 class="h4">食宿</h4>
                <p class="p">
                  <template v-for="sx in detail.courseMonies">
                    <span v-if="sx.type == 2">{{ sx.aveMony }}元/人</span>
                  </template>
                  <span v-if="(detail.courseMonies || []).length == 0">无</span>
                </p>
              </li>
              <li>
                <h4 class="h4">补贴费用</h4>
                <p class="p" v-if="(detail.courseMonies || []).length >0">
                  <template v-for="sx in detail.courseMonies">
                    <span v-if="sx.type == 3" style="margin-right: 18px">交通补贴：{{ sx.aveMony }}元/人</span>
                    <span v-if="sx.type == 4">生活补贴：{{ sx.aveMony }}元/人</span>
                  </template>
                </p>
                <p class="p" v-if="(detail.courseMonies || []).length == 0">无</p>
              </li>
              <li>
                <h4 class="h4">
                  培训内容
                </h4>
                <p class="p text" v-html="detail.trainDetail">
                </p>
              </li>
            </ul>
          </section>
        </div>
        <div>
          <div class="info p18">
            <div class="time" :class="projectStatus(detail.courseStatus).color">
              <img src="@/static/project/time1.png" alt="" v-if="detail.courseStatus == '1'">
              <img src="@/static/project/time.png" alt="" v-if="detail.courseStatus == '2'">
              <img src="@/static/project/time.png" alt="" v-if="detail.courseStatus == '3'">
              <img src="@/static/project/time2.png" alt="" v-if="detail.courseStatus == '4'">
              {{ projectStatus(detail.courseStatus).text }}
            </div>
            <el-button class="apply" :class="btnType(detail.buttonStatus).color" :disabled="(detail.buttonStatus == 1 || detail.buttonStatus == 2)?false:true"
              @click="disablePeople(detail.id)">
              {{ btnType(detail.buttonStatus).text }}
            </el-button>
            <div class="share">
              <shareCom :qrUrl='shareUrl' />
            </div>
            <ul class="ul">
              <li>
                <h4 class="h4">招生人数</h4>
                <p class="p">{{ detail.stuNum }}人</p>
              </li>
              <li>
                <h4 class="h4">培训方式</h4>
                <p class="p">{{ detail.trainIng }}</p>
              </li>
              <li>
                <h4 class="h4">培训地点</h4>
                <p class="p">{{ detail.address }}</p>
              </li>
            </ul>
            <ul class="box" v-if="userInfo.userType == 1 && userInfo">
              <li>
                <h4><img src="@/static/project/phone.png" alt="">联系方式</h4>
                <!-- <el-popover popper-class="contact-way-pop" placement="bottom-start" :width="282" trigger="hover">
                  <div slot="reference">
                    <img src="/static/images/project/qust.png" alt="">
                  </div>
                  <div class="pop-con">
                    <p>您可以留言给培训机构，老师看到会第一时间回复哦！</p>
                  </div>
                </el-popover> -->
              </li>
              <li>
                <div class="phone" v-if="link">
                  {{ (link.teacherName || '') + ' ' + link.linkPhone }}
                </div>
                <p class="blue" @click="openQuestions"><img src="@/static/project/msg.png" alt="">留言提问</p>
              </li>
            </ul>
          </div>
          <div class="tips p18">
            <h3 class="title">
              注意事项
            </h3>
            <p class="p">
              1.报名需审核，审核通过方可参加培训。
            </p>
            <p>
              2.计划培训时间为暂定的培训时间，具体培训开始日期以后续通知为准。
            </p>
            <p>
              3.残疾人学员每人每年可享受不超过3次免费培训，同一课程类别同一等级的培训原则上不可重复参加。
            </p>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :title="show.title" width="456px" :close-on-click-modal="false" :content="show.text" :append-to-body="true" :visible="show.isShow" @close="onClose"
      @confirm="onConfirm($event,show.type)">
      <ul class="dialog_ul" v-if="show.type == 1">
        <li>
          <div class="dialog_ul_name">姓名</div>
          ：

          <div class="dialog_ul_text">{{ disableList.userName }}</div>
        </li>
        <li>
          <div class="dialog_ul_name">性别</div>
          ：

          <div class="dialog_ul_text">{{ disableList.sex }}</div>
        </li>
        <li>
          <div class="dialog_ul_name">年龄</div>
          ：

          <div class="dialog_ul_text">{{ disableList.age }}</div>
        </li>
        <li>
          <div class="dialog_ul_name">身份证号</div>
          ：

          <div class="dialog_ul_text">{{ disableList.idNumber }}</div>
        </li>
        <li>
          <div class="dialog_ul_name">残疾证号</div>
          ：
          <div class="dialog_ul_text">{{ disableList.dcNumber }}</div>
        </li>
        <li>
          <div class="dialog_ul_name">残疾类别</div>
          ：
          <div class="dialog_ul_text">{{ disableList.disabledType + '/' + disableList.disabledLevel }}</div>
        </li>
        <li>
          <div class="dialog_ul_name">文化程度</div>
          ：
          <div class="dialog_ul_text">
            <MulSelects code="user_edu" v-model="disableList.edu" @change="changeEdu" class="width218" width='138px' :clearable='false' :defaultValue="disableList.edu" />
          </div>
        </li>
      </ul>
      <div v-else>
        {{show.text}}
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button @click="onClose">取 消</el-button>
        <el-button type="primary" v-if="show.type == 1" @click="onConfirm($event,show.type)">报名</el-button>
        <el-button type="primary" v-else @click="onClose">确认</el-button>
      </div>
    </el-dialog>

    <el-dialog title="" :content="show.text" width="456px" :append-to-body="true" :visible="show.isShow2" @close="onClose">
      <div class="title flex" slot="title" style="align-items: center;">
        <img src="@/static/project/success.png" style="width:24px;height:24px;margin-right: 12px" alt="">
        <h3 style="font-size: 18px;color: #00120A;line-height: 28px; ">
          培训预约成功
        </h3>
      </div>
      <p class="p" style="font-size: 16px;color: rgba(102, 113, 108, 1);margin-top: 6px;line-height: 24px;">
        报名开始后我们会发送站内消息和短信提醒您报名事宜，请您留意短信、平台站内消息提醒，不要错过报名哦！
      </p>
      <div slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button type="primary" round @click="onClose" style="display: flex;margin: 60px auto 0 auto">关闭</el-button>
      </div>

    </el-dialog>
    <el-dialog title="留言提问" width="456px" :append-to-body="true" :visible="showQuestions" @close="onClose" @confirm='submitQuestions'>
      <div style="padding: 18px 18px 10px;">
        <el-input v-model.trim="questionsVal" :rows="4" type="textarea" placeholder="请输入留言内容" />
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button type="primary" round @click="submitQuestions">提交</el-button>
      </div>
    </el-dialog>
    <authentication :show="isHide" @close="onClose"></authentication>

  </div>
</template>
<script >
import { countDown } from '@/plugins/utils/utils.js';
import MulSelects from 'components/selectComponents/MulSelects';
import authentication from '@/components/public/authentication';
import disType from '@/components/disType/index.vue';
import shareCom from 'components/share/index2.vue';

export default {
  components: {
    MulSelects,
    authentication,
    disType,
    shareCom
  },
  data() {
    return {
      showQuestions: false,
      questionsVal: '',
      userInfo: '',
      isHide: false,
      disableList: {},
      dict: [],
      getTime: {},
      link: {},
      timer: null,
      form: {
        courseId: '',
        labelGrade: '0',
        timeSuperposition: '0'
      },
      type: false,
      show: {
        isShow: false,
        isShow2: false,
        title: '培训报名',
        text: '',
        confirmText: '报名',
        cancelText: '',
        isCancel: true,
        isConfirm: true,
        type: 1,
        id: ''
      },
      detail: {},
      shareUrl: ''
    };
  },
  methods: {
    onConfirm(val, type) {
      this.apply(this.show.id);
    },
    openQuestions() {
      console.log(this.getLoginInfo('请登录后留言'));
      if (this.getLoginInfo('请登录后留言')) return;
      if (this.userInfo.userType == 1) {
        this.showQuestions = true;
      } else if (this.userInfo.userType == 2) {
        this.$message.warning('企业不允许留言');
      } else {
        // 学员，未认证
        this.isHide = true;
        return;
      }
    },
    getLoginInfo(text) {
      this.type = false;
      if (!this.userInfo || !this.userInfo.userType) {
        this.type = true;
        this.$confirm(text ? text : '请登录后点击报名', '未登录', {
          confirmButtonText: '登录',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            let { pathname, search } = window.location;
            this.$router.push('/Login/?redirect=' + pathname + search);
          })
          .catch(() => {});
      } else {
        this.type = false;
      }
      return false;
    },
    submitQuestions() {
      if (!this.questionsVal) {
        this.$message.warning('请输入留言内容');
        return false;
      }
      this.$api
        .disabledLiuYan({
          content: this.questionsVal,
          courseId: this.$route.query.id,
          teacherId: this.link.memberId
        })
        .then(res => {
          res = res.data;
          if (res.success) {
            this.$message.success('留言成功');
            this.showQuestions = false;
          }
        });
    },
    changeEdu(val) {
      this.$api
        .updateDisabledInfo({
          edu: val,
          id: this.userInfo.id || ''
        })
        .then(res => {});
    },
    onClose() {
      this.show.isShow = false;
      this.show.isShow2 = false;
      this.showQuestions = false;
      this.isHide = false;
    },
    info(id) {
      this.$api.queryCourseDetailByIdApi({ courseId: id }).then(res => {
        let { data, success, msg } = res.data;
        if (!success && msg) return this.$message.error(msg);
        this.detail = data.courseDetailOutDTO;
        this.link = data.courseTeacherOutDTOList;
        let disabledLabel = data.courseDetailOutDTO.disabledLabelAndLevelOutDTO || [];
        this.show.id = id;
        let { courseName, zhuName, schooldName } = this.detail;
        if (disabledLabel.length) {
          this.dict = disabledLabel.reduce((cur, item, index, arr) => {
            cur.push({
              isTxt: true,
              name: item.disabledType,
              level: item.disabledLevel
            });
            if (index < arr.length - 1) {
              cur.push({ isTxt: false, name: '、' });
            }
            return cur;
          }, []);
        }
        if (this.timer) clearInterval(this.timer);
        this.timer = setInterval(() => {
          this.getTime = {
            ...countDown(
              data.courseDetailOutDTO.appplyBeginTime,
              data.courseDetailOutDTO.appplyEndTime
            )
          };
        }, 1 * 60 * 1000);
        return (this.getTime = {
          ...countDown(
            data.courseDetailOutDTO.appplyBeginTime,
            data.courseDetailOutDTO.appplyEndTime
          )
        });
      });
    },
    apply(id) {
      this.show.isShow = false;
      this.$api.getSignUpApi(this.form).then(res => {
        this.show.isShow = true;
        const { success, msg, code } = res.data;
        if (!success && msg) {
          this.show.title = '与报名要求不匹配';
          this.show.text = msg;
          this.show.cancelText = '返回';
          this.show.confirmText = '继续报名';
          this.show.type = 2;
          if (code == '8502') return (this.show.isConfirm = false);
          return;
        } else {
          this.show.title = '报名信息提交成功';
          this.show.text = '等待人工审核，您可在我的报名中查询审核结果';
          this.show.isCancel = false;
          this.show.isConfirm = false;
          this.show.type = 3;
          this.show.cancelText = '确认';
          this.detail.buttonStatus = 3;
          this.$message.success(msg);
        }
      });
    },
    disablePeople(id, city) {
      if (this.getLoginInfo()) return;
      this.form.courseId = id;
      if (this.userInfo.userType === '2') {
        return this.$message.error('企业用户不允许报名');
      } else if (this.userInfo.userType === '4') {
        this.isHide = true;
        return;
      } else {
        if (this.detail.buttonStatus == '2') {
          this.$api.subscribeApplyApi({ courseId: id }).then(res => {
            let { data, code, success, msg } = res.data;
            if (!success && msg) return;
            this.info(this.$route.query.id);
            this.show.isShow2 = true;
          });
        } else {
          this.$api.disabledInformationApi().then(res => {
            let { data, success, msg, code } = res.data;
            if (code == '8501') return this.$message.error(msg);
            if (code == '8502') return (this.isHide = true);

            if (success) {
              this.show.isShow = true;
              this.show.id = id;
              this.disableList = data;
            }
          });
        }
      }
    },
    projectStatus(state) {
      let text = {
        1: `该培训报名截止时间：${this.$dateFormat(this.detail.appplyEndTime, 'YYYY年MM月DD日')}`,
        2: `该培训报名开始时间：${this.$dateFormat(this.detail.appplyBeginTime, 'YYYY年MM月DD日')}`,
        3: '该项目培训暂未开始',
        4: '该项目正在培训中…',
        5: '该项目培训已结束'
      }[state];
      let color = {
        1: 'org',
        2: 'green',
        3: 'org',
        4: 'blue',
        5: 'gray'
      }[state];
      return {
        text,
        color
      };
    },
    btnType(state) {
      let text = {
        1: `立即报名`,
        2: `立即预约`,
        3: '报名已结束',
        4: '报名已结束',
        5: '报名已结束',
        6: '报名成功',
        7: '预约成功'
      }[state];
      let color = {
        1: 'green',
        2: 'org',
        3: 'gray',
        4: 'gray',
        5: 'gray',
        6: 'green_1',
        7: 'org_1'
      }[state];
      return {
        text,
        color
      };
    }
  },
  mounted() {
    let id = this.$route.query.id || '';
    if (id) this.info(id);
    let userInfo = localStorage.getItem('userInfo')
      ? JSON.parse(localStorage.getItem('userInfo'))
      : {};
    this.userInfo = userInfo;
    this.$emit('goRouter', '/train/');
    this.shareUrl = window.location.href;
  }
};
// import {
//   queryCourseDetailByIdApi,
//   disabledInformationApi,
//   subscribeApplyApi,
//   getSignUpApi
// } from '@/apis/home.js';
</script>

<style scoped lang="scss">
.project_detail {
  .breadcrumb {
    display: flex;
    align-items: center;
    padding: 30px 0;
    overflow: hidden;
    .name {
      line-height: 20px;
      vertical-align: middle;
      color: #666666;
      margin-top: 4px;
    }
  }
  .content {
    width: 1200px;
    margin: 0 auto;
    // display: flex;
    .my_content {
      position: relative;
      display: flex;
      margin-bottom: 36px;

      .p18 {
        padding: 18px;
      }

      .init {
        width: 792px;
        margin-right: 24px;

        .header {
          background: white;

          h2 {
            font-size: 28px;
            font-family: MicrosoftYaHeiSemibold;
            color: #00120a;
            line-height: 36px;
            margin-bottom: 18px;
          }

          p {
            margin-top: 6px;

            label {
              color: #00120a;
              font-size: 16px;
              line-height: 24px;
            }

            a {
              color: #0077bb;
              font-size: 16px;
              line-height: 24px;
            }
          }
        }

        section {
          background: white;
          margin-top: 12px;

          .title {
            font-size: 20px;
            font-family: MicrosoftYaHeiSemibold;
            color: #00120a;
            font-weight: bold;
            line-height: 28px;
            margin-bottom: 6px;
          }

          .ul {
            li {
              position: relative;
              padding: 18px 0;
              display: flex;

              &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 1px;
                background-image: linear-gradient(
                  to right,
                  rgba(0, 18, 10, 0.1) 0%,
                  rgba(0, 18, 10, 0.1) 50%,
                  transparent 0%
                );
                background-repeat: repeat-x;
                background-size: 14px 1px;
              }

              &:last-child::after {
                display: none;
              }

              &:last-child {
                padding-bottom: 0;
              }

              h4 {
                font-size: 16px;
                line-height: 24px;
                margin-right: 12px;
                width: 80px;
                text-align-last: justify;
                word-break: break-all; // 解决text-align-last: justify;不兼容火狐兼容问题
                text-justify: distribute; // 解决text-align-last: justify;不兼容火狐兼容问题
              }

              p {
                flex: 1;
                font-size: 16px;
                line-height: 24px;
                color: #66716c;
              }

              ::v-deep .text {
                img {
                  max-width: 85%;
                }
              }
            }
          }
        }
      }

      .info {
        width: 384px;
        background: white;

        .time {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          line-height: 24px;
          height: 42px;

          img {
            margin-right: 5px;
          }

          &.org {
            color: #ff9600;
            background: rgba(255, 150, 0, 0.06);
            border: 1px solid rgba(255, 150, 0, 0.06);
          }

          &.green {
            color: #56c1ab;
            background: rgba(86, 193, 171, 0.08);
            border: 1px solid rgba(86, 193, 171, 0.06);
          }

          &.blue {
            color: #0077bb;
            background: rgba(0, 119, 187, 0.06);
            border: 1px solid rgba(0, 119, 187, 0.06);
          }

          &.gray {
            color: #66716c;
            background: rgba(0, 18, 10, 0.05);
            border: 1px solid rgba(0, 18, 10, 0.05);
          }
        }

        .apply {
          width: 100%;
          height: 52px;
          border-radius: 2px;
          margin-top: 12px;
          font-size: 18px;
          color: white;
          border: none;

          &.green {
            background: #56c1ab;
          }

          &.org {
            background: #ff9600;
          }

          &.green {
            background: #56c1ab;
          }

          &.green_1 {
            background: rgba(86, 193, 171, 0.6);
          }

          &.org_1 {
            background: rgba(255, 150, 0, 0.6);
          }

          &.gray {
            color: #99a09d;
            background: rgba(0, 18, 10, 0.1);
          }
        }

        .share {
          position: relative;
          padding: 18px 0;

          &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background-image: linear-gradient(
              to right,
              rgba(0, 18, 10, 0.1) 0%,
              rgba(0, 18, 10, 0.1) 50%,
              transparent 0%
            );
            background-repeat: repeat-x;
            background-size: 14px 1px;
          }
        }

        .ul {
          li {
            position: relative;
            padding-top: 18px;
            display: flex;

            h4 {
              font-size: 16px;
              line-height: 24px;
              margin-right: 6px;
            }

            p {
              flex: 1;
              font-size: 16px;
              line-height: 24px;
              color: #66716c;
            }
          }
        }

        .box {
          background: #f9f9f9;
          border-radius: 2px;
          margin-top: 18px;

          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 0;
            margin: 0 12px;

            h4 {
              display: flex;
              align-items: center;
              font-size: 16px;

              img {
                margin-right: 5px;
              }
            }

            &:first-child {
              border-bottom: 1px dashed rgba(0, 18, 10, 0.1);
            }

            .phone {
              font-size: 16px;
              color: #66716c;
            }

            .blue {
              display: flex;
              align-items: center;
              cursor: pointer;

              img {
                margin-right: 5px;
              }

              color: #0077bb;
            }
          }
        }
      }

      .tips {
        width: 384px;
        background: white;
        margin-top: 12px;

        .title {
          font-size: 20px;
          font-family: MicrosoftYaHeiSemibold;
          font-weight: bold;
          line-height: 28px;
        }

        p {
          font-size: 16px;
          color: #66716c;
          line-height: 24px;
          margin-top: 12px;
        }
      }
    }
  }
}
</style>
<style lang="scss" >
.dialog_ul {
  li {
    font-size: 14px;

    font-weight: 400;
    color: #66716c;
    line-height: 24px;
    margin-bottom: 6px;
    display: flex;
    align-items: center;

    .dialog_ul_name {
      width: 70px;
      display: inline-block;
      text-align-last: justify;
      vertical-align: middle;
      text-align: justify;
      text-justify: distribute;
    }
  }
}
</style>

