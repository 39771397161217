<!--
 * @Description: div
 * @Version: 
 * @Autor: houcf
 * @Date: 2023-04-23 18:00:30
 * @LastEditTime: 2023-04-28 11:21:36
-->
<template>
  <div>
    <trainDetail/>
  </div>
</template>
<script>
import trainDetail from '@/components/public/trainDetail';

export default {
  components:{
    trainDetail
  },
  mounted() {
      this.$emit("goRouter", "/shanxi/train/");
  },
  
}
</script>