<!--
 * @Description: 
 * @Version: 
 * @Autor: houcf
 * @Date: 2023-04-25 17:00:57
 * @LastEditTime: 2023-04-26 17:15:31
-->
<template>
  <el-select :size="size" v-model="value1" :placeholder="placeholder" @change="handleChange" filterable clearable>
    <el-option v-for="item in options" :key="item.index" :label="item.ITEM_TEXT" :value="item.ITEM_VALUE"></el-option>
  </el-select>
</template>

<script>
export default {
  name: 'MulSelect',
  props: ['code', 'defaultValue', 'size', 'placeholder', 'value'],
  model: {
    event: 'change',
    prop: 'value'
  },
  data() {
    return {
      options: [],
      value1: ''
    };
  },
  watch: {
    value: {
      handler: function (val) {
        this.value1 = val;
      }
    }
  },
  methods: {
    handleChange(value) {
      this.$emit('setMValue', value);
      this.$emit('change', value);
    },
    initDictData() {
      //根据字典Dict, 初始化字典数组
      let code = this.code;
      this.$axios.get('/api/app-jycy-sysdict/queryDictTextByDiCode?code=' + code).then(res => {
        this.options = res.data.data;
      });
    }
  },
  created() {
    // this.value = this.defaultValue;
    this.initDictData();
  }
};
</script>
