<!--
 * @Description: 
 * @Version: 
 * @Autor: houchuanfang
 * @Date: 2022-10-21 09:19:23
 * @LastEditTime: 2023-04-24 15:14:41
-->
<template>
  <div class="share-con">
    <ul>
      <li class="label"> 分享到：</li>
      <li>
        <img @click="shareToQQ" src="@/static/project/qq.png" alt="">
      </li>
      <li>
        <el-popover popper-class="share-popover2" placement="bottom" title="微信扫一扫" :width="150" trigger="hover" content="this is content, this is content, this is content">
          <div class="content">
            <div :id="qrid || 'qrcode'" class="qrcode"></div>
            <!-- <qrcode-vue :value="qrUrl" :size="100" level="H" /> -->
          </div>
          <div slot="reference">
            <img src="@/static/project/wx.png" alt="">
          </div>
        </el-popover>
      </li>
      <li class="last">
        <img @click="shareToMicroblog" src="@/static/project/wb.png" alt="">
      </li>
    </ul>
  </div>
</template>
<script>
import QRCode from 'qrcodejs2';
// import useClipboard from 'vue-clipboard3';
// const { toClipboard } = useClipboard();
export default {
  components: {},
  props: {
    qrUrl: {
      type: String,
      default: ''
    },
    qrid: {
      type: String,
      default: ''
    },
    url: {}
  },
  mounted() {
    this.qrCode();
  },
  methods: {
    // 生成二维码
    qrCode() {
      let qrUrl = this.qrUrl || window.location.href;
      this.$nextTick(function () {
        document.getElementById(this.qrid || 'qrcode').innerHTML = '';
        var id = this.qrid || 'qrcode';
        let qrcode = new QRCode(id, {
          width: 89 * 10,
          height: 89 * 10,
          text: qrUrl, // 二维码内容 也可以放url
          colorDark: '#000',
          colorLight: '#fff',
          correctLevel: QRCode.CorrectLevel.H
        });
        qrcode.clear(); // clear the code.
        qrcode.makeCode(qrUrl); // make another code.
      });
    },
    shareToQQ() {
      // let qrUrl = window.location.href
      //分享到QQ好友(PC端可用)
      //此处分享链接内无法携带图片
      this.openWin(
        'https://connect.qq.com/widget/shareqq/index.html?url=' +
          encodeURIComponent(window.location.href) +
          '&title=全国残疾人职业培训管理服务系统竭诚服务！' +
          +'&desc='
      );
    },
    //分享到微博(可用)
    shareToMicroblog() {
      //自定义内容
      //跳转地址
      this.openWin(
        'https://service.weibo.com/share/share.php?url=' +
          encodeURIComponent(window.location.href) +
          '&title=全国残疾人职业培训管理服务系统竭诚服务！' +
          '' +
          '&pic=' +
          '' +
          '&searchPic=true'
      );
    },
    openWin(url) {
      var name; //网页名称，可为空;
      var iWidth = 600; //弹出窗口的宽度;
      var iHeight = 670; //弹出窗口的高度;
      //window.screen.height获得屏幕的高，window.screen.width获得屏幕的宽
      var iTop = (window.screen.height - 30 - iHeight) / 2; //获得窗口的垂直位置;
      var iLeft = (window.screen.width - 10 - iWidth) / 2; //获得窗口的水平位置;
      window.open(
        url,
        name,
        'height=' +
          iHeight +
          ',innerHeight=' +
          iHeight +
          ',width=' +
          iWidth +
          ',innerWidth=' +
          iWidth +
          ',top=' +
          iTop +
          ',left=' +
          iLeft +
          ',toolbar=no,menubar=no,scrollbars=auto,resizable=no,location=no,status=no'
      );
    },
    async copy(text) {
      try {
        text = text || window.location.href;
        await toClipboard(window.location.href); //实现复制
        this.$message.success('链接复制成功！');
        console.log('Success');
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>
<style lang='less' scoped>
.qrcode {
  display: inline-block;
  margin-top: 10px;
  width: 89px;
  height: 89px;
  /deep/canvas {
    width: 300px;
    height: 300px;
  }
  /deep/ img {
    width: 100% !important;
    height: 100% !important;
    // width: 114px !important;
    // height: 114px !important;
  }
}
.share-con {
  ul {
    display: flex;
    align-items: center;
    li {
      cursor: pointer;
      &:not(.label) {
        margin-right: 18px;
      }
      &.label {
        font-size: 16px;
        color: rgba(102, 113, 108, 1);
        line-height: 21px;
        display: inline-block;
        width: 65px;
      }
      img {
        vertical-align: middle;
      }
    }
  }
}
</style>
<style lang='scss'>
.el-popover.share-popover2 {
    text-align: center !important;

    .el-popover__title {
        color: #76839b;
    }
}
</style>